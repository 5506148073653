.error {
	height: 100vh;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	background-color: #000;
	color: #fff;
	font-weight: 600 (Regular);
	font-family: Montserrat, sans-serif, Arial, Helvetica, sans-serif;
	font-weight: 600 (Regular);
}
