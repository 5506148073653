.layout-wraper {
	font-family: 'Poppins', 'Roboto', sans-serif;
	font-weight: 500;
	font-size: 16px;
	color: #222;

	h1 {
		font-weight: 700;
		font-size: 28px;
	}

	h2 {
		font-weight: 700;
		font-size: 24px;
	}

	h3 {
		font-weight: 700;
		font-size: 20px;
	}

	p {
		font-weight: 400;
		font-size: 16px;
	}

	ul {
		list-style-type: none;
	}

	.text-adjustment {
		text-align: justify;
		text-justify: distribute;

		margin: 0 auto;
		padding: 20px;
		line-height: 2rem;
	}
}
